@import '../../scss/imports';

#error-dialog {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: 80vh;

  .details-expansion {
    font-size: .8rem;
    margin: 0;
    padding: 0 .625rem;

    li {
      margin: .875rem;
    }
  }

  .error-message {
    margin-bottom: .5rem;
    margin-top: .25rem;
  }

  .error-id {
    margin-bottom: 1.125rem;
    margin-top: 1.125rem;
  }

  .error-dialog-button-container {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    width: 100%;

    button {
      width: 8.3125rem;
    }
  }
}
