@import '../../scss/imports';

div {
  .rd3t-tree-container {
    background: #ededed;
    border-radius: .5rem;
    box-sizing: border-box;
    height: 65vh;
    overflow: visible;
    width: 100%;
  }
}

#back-button {
  background-color: rgb(98, 0, 238);
}

#organizationTree {
  min-width: 12.5rem;
  overflow: scroll;
}

.rd3t-link {
  stroke: #2593b8 !important;
}

.clear-button {
  background: #2593b8;
}

.tree-node {
  background: #fff;
  border-radius: .3125rem;
  box-shadow: 0 .1875rem 1.5rem rgba(0, 0, 0, .1);
  height: 100%;
  padding: 1rem;
  text-align: left;

  h4,
  h6 {
    margin: .3125rem auto;
  }

  h4 {
    font-family: $font-demi;
    font-size: .875rem;
  }

  h6 {
    font-family: $font-regular;
    font-size: .75rem;
  }
}

.folder-node {
  background: #dadada;
  display: flex;
  height: 2.8125rem;
  padding: 0;

  .folder-node-header {
    margin: .75rem .8125rem;
  }

  .child-count {
    align-items: center;
    border-left: .0625rem solid rgba(0, 0, 0, .2);
    display: flex;
    padding-left: .25rem;
  }
}

.node-icon-title {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.node-icon {
  align-self: flex-start;
  margin-right: .5rem;
  padding-top: .125rem;
}

.organization-node {
  background: #fff;
}

.person-node {
  background: #fff;
}

.lot-node {
  background-color: #fff;
}

.node-header {
  align-items: center;
  display: grid;
  gap: .5rem;
  grid-template-columns: auto 1fr;

  margin: 0;

  h4 {
    margin: 0;
    text-align: left;
  }

  .child-count {
    height: 100%;
    text-align: right;

    span {
      margin-left: .5rem;
    }
  }
}

.folder-node-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.building-icon {
  stroke: none;
}

.person-icon {
  stroke: none;
}

.place-icon {
  stroke: none;
}

.no-dashboard {
  .rd3t-tree-container {
    height: 31.25rem;
  }
}
