/* APP COLORS */
$white: #fff;
$black: #000;
$blue: #2196f3;
$primary-color: #6200ee;
$primary-color-dark: #4c00d5;
$secondary-color: #4285f4;
$tertiary-color: #e9e9e9;
$tertiary-text-color: $black;
$priority-color: #f11a1a;
$warning-color: #f44336;
$warning-text-color: $white;
$action-color: $secondary-color;
$label-color: #878787;
$disabled-color: #c3c3c3;
$text-color: #121212;
$secondary-text-color: rgba($black, .6);
$body-bg-color: #fafafa;
$light-text-color: rgba($white, .8);

$border: 1px solid $secondary-text-color;

/* TEXTURES */
$border-radius: .25rem;
$box-shadow: 0 .125rem .25rem rgba($black, .2);
$md-box-shadow: 0 .125rem .125rem 0 rgba(0, 0, 0, .14), 0 .0625rem .3125rem 0 rgba(0, 0, 0, .12), 0 .1875rem .0625rem -.125rem rgba(0, 0, 0, .2);


/* FONTS */
$font-path: '../assets/fonts';
$font-ultra-light: 'AvenirNextLTW01-UltraLi', Arial;
$font-light: 'Avenir Next W01 Light', Arial;
$font-regular: 'AvenirNextLTW01-Regular', Arial;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial;
$font-demi: 'Avenir Next LT W01 Demi', Arial;
$font-heavy: 'AvenirNextLTW01-Heavy', Arial;

$custom-typography-styles: (
  letter-spacing: normal,
);

/* SCREEN SIZES */
$xxs: 25em; // 400px
$xs: 30em;  // 480px
$sm: 37.5em;// 600px
$smt: 50em; // 800px
$md: 60em;  // 960px
$lg: 80em;  // 1280px
$xl: 90em;   // 1440px
$ipad: 48em; //768px
$landscape: 64em;

$wrapper: 75rem;

$ph-header-background-color: #6200ee;
$ph-header-action-button-color: #fff !important;
