@import '../../scss/imports';

$tab-width: 10rem;
$tab-width-mobile: 7.8125rem;
$tab-margin: 2rem;
$time: 300ms;

.tabs-wrapper {
  background-color: $primary-color-dark;
}

.tab-navigation {
  margin-left: 12%;
  max-width: 75rem;
  padding: 0 1rem;

  .tab-navigation-menu {
    height: 3rem;
    position: relative;

    a {
      color: rgba(#fff, .7);
      cursor: pointer;
      display: inline-block;
      font-family: $font-demi;
      font-size: .8125rem;
      font-weight: normal;
      height: 3rem;
      line-height: 3rem;
      margin-right: $tab-margin;
      min-width: $tab-width;
      padding: 0;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 200ms ease-in-out;

      @include max-screen($ipad) {
        margin: 0;
        min-width: $tab-width-mobile;

        &.active {
          .bottom-border {
            transform: translateX(-50%) scale(1);
            width: $tab-width-mobile;
          }
        }
      }

      &:hover {
        color: rgba(#fff, .7);
      }

      &:last-child {
        margin-right: 0;
      }

      .bottom-border {
        background-color: #fff;
        bottom: 0;
        display: inline-block;
        height: .125rem;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) scale(0);
        transition: transform $time ease-in-out;
        width: 0;
      }

      &.active {
        color: #fff;
      }
    }

    .link-container {
      display: flex;
      overflow-x: auto;
      position: relative;

      @include min-screen(48.0625em) {
        &::after {
          background-color: #fff;
          bottom: 0;
          content: '';
          display: inline-block;
          height: .125rem;
          left: 0;
          position: absolute;
          transform: translateX(0);
          transition: transform $time ease-in-out, width $time ease-in-out;
          width: $tab-width;
        }

        &.active-0 {
          &::after {
            transform: translateX(0);
          }
        }

        &.active-1 {
          &::after {
            transform: translateX(calc(#{$tab-width} + #{$tab-margin}));
          }
        }

        &.active-2 {
          &::after {
            transform: translateX(calc((#{$tab-width} + #{$tab-margin}) * 2));
          }
        }

        &.active-3 {
          &::after {
            transform: translateX(calc((#{$tab-width} + #{$tab-margin}) * 3));
          }
        }
      }
    }
  }
}
