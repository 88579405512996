* {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

html,
body {
  color: $text-color;
  font-family: $font-regular;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  height: auto;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

main {
  padding-bottom: 2.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
html {
  font-family: $font-regular;
  font-weight: normal;
}

hr {
  border: 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

button,
input {
  appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  width: auto;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding: 0 1rem;
  position: relative;
}

.wrapper-full {
  margin: 0;
  max-width: 100%;
  padding: 0;
}

.md-tab-indicator {
  background: $white;
  min-height: .25rem;
  transition-duration: initial;
  transition-timing-function: initial;
}

.md-list {
  background: inherit;
  background-color: #fff;
}

.icon-button {
  border: 0;
  height: 2.25rem;
  padding: 0;
  width: 2.25rem;

  .md-icon {
    font-size: 1.5rem;
    vertical-align: middle;
  }

  &.black {
    color: $secondary-text-color;
  }

  &.white {
    color: $white;
  }

  +.icon-button {
    margin-left: 1rem;
  }
}

.button {
  height: 2.25rem;
  margin: 0;
  text-transform: uppercase;

  &.ghost {
    border: 1px solid $black;
    color: $black;
  }
}

.md-avatar {
  border: 0;
}

.user-avatar {
  align-items: center;
  background-color: #26c6da;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: .875rem;
  height: 2rem;
  justify-content: center;
  width: 2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $white inset !important;
}
