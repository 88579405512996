@import 'imports';

@mixin transition() {
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
}

.fade-enter {
  opacity: 0;
  position: absolute;
  width: calc(100% - 2rem);
}

.fade-enter.fade-enter-active {
  @include transition();
  opacity: 1;
}

.fade-exit {
  @include transition();
  opacity: 1;
  z-index: -1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  z-index: -1;
}

.slide-enter {
  background: $body-bg-color;
  position: absolute;
  transform: translate3d(100%, 0, 0);
  width: calc(100% - 2rem);
  z-index: 1;
}

.slide-enter.slide-enter-active {
  @include transition();
  transform: translate3d(0, 0, 0);
}

.slide-exit {
  @include transition();
  opacity: 0;
  z-index: -1;
}

.transition {
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
}
