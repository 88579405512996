@import '../../scss/imports';

.progress-container {
  background-color: #e2e2e2;
  height: .25rem;
  left: 0;
  margin-top: 4rem;
  position: fixed;
  width: 100vw;

  .progress-indicator {
    background-color: #3c0c81;
    height: 100%;
    transition: width .2s ease-in;
  }

}
