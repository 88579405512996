@import '../../scss/imports';

#layout {
  margin-top: 1.5rem;

  .col-xs-9 {
    max-width: 100%;
  }

  .wrapper {
    margin: 0 auto;
    max-width: 1200px;
    min-width: 100vw;

  }
}
