@use 'variables' as theme;
@use 'everything' as *;
@include react-md-utils;

.rmd-button {
  font-family: theme.$font-demi;

  &:disabled {
    opacity: .4;
  }
}

.ph-checkbox {
  @include rmd-button-theme-update-var(icon-size, 1.5rem);
  @include rmd-icon-theme-update-var(color, theme.$primary-color);
  @include rmd-theme-update-var(secondary, theme.$primary-color);
  @include rmd-theme-update-var(text-secondary-on-background, rgba(#000, .25));
  height: 2.5rem;

  label {
    cursor: pointer;
    flex-grow: 1;
    font-size: .875rem;
  }

  &.top-control {
    label {
      font-family: theme.$font-demi;
    }
  }

  &.secondary {
    @include rmd-icon-theme-update-var(color, theme.$secondary-color);
    @include rmd-theme-update-var(secondary, theme.$secondary-color);
    @include rmd-theme-update-var(text-secondary-on-background, rgba(#000, .25));
  }
}
