@import '~@react-md/button/dist/mixins';

$border: 1px solid #dedede;

.ph-table {
  margin-bottom: .5rem;

  table {
    width: 100%;
  }

  thead {
    font-family: $font-demi;
  }

  th {
    background: #f2f2f2;
    border-bottom: $border;
    border-top: $border;
    cursor: pointer;
    font-weight: normal;
    height: 4.5rem;
    padding: 1rem;
    position: sticky;
    top: 6.9375rem;
    white-space: nowrap;
    z-index: 1;
  }

  th-edit-menu,
  th::after {
    border-bottom: $border;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    width: 100%;
  }

  .display-menu::after {
    border-bottom: $border;
    bottom: -1px;
  }

  .date {
    text-transform: lowercase;
  }

  th,
  td {
    color: $secondary-text-color;
    font-size: .875rem;
    padding: 1rem;
    text-align: left;
  }

  .role {
    text-transform: capitalize;
    width: 15%;
  }

  .disabled-sort {
    cursor: default;
  }

  .th-item {
    display: flex;
    position: relative;

    span {
      line-height: 1.5rem;
      vertical-align: middle;
    }

    .th-icon {
      align-items: center;
      display: flex;
      height: 1.5rem;
      justify-content: center;
      width: 1.5rem;
    }
  }

  tbody {
    tr {
      border-bottom: $border;
    }

    td {
      svg {
        margin: 0 .75rem;
      }
    }
  }

  .circle-avatar {
    align-items: center;
    border: solid #fff 1px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: .875rem;
    height: 2rem;
    justify-content: center;
    margin-right: 1.25rem;
    width: 2rem;
  }

  .avatar-name-container {
    align-items: center;
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
  }

  .load-more-button {
    margin-top: 3rem;
  }

  .checked {
    background: #e4f2fe;
  }

  th .md-selection-control-container,
  td .md-selection-control-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .lot-checkbox {
    width: 10%;
  }

  .lot-displayName {
    width: 20%;
  }

  .lot-organizations {
    width: calc(70% - 4.25rem);
  }

  .disabled .md-fake-btn {
    color: #dadada;
  }

  .deactivated {
    background: #f2f2f2;

    td {
      color: #b8b8b8;
    }
  }

  .lot-multiLotEdit {
    width: 4.25rem;
  }

  .th-edit-menu {
    .th-icon {
      height: 0;
      width: 0;
    }
  }
}

.table-action-bar {
  align-items: center;
  display: flex;
  height: 4.375rem;
  justify-content: space-between;

  .add-item-button {
    color: $secondary-text-color;
    font-family: $font-demi;
  }

  .filter-container,
  .add-item-container,
  .add-item-button {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
}

.table-menu-button {
  @include rmd-button-theme-update-var(icon-size, 2.5rem);

  + .rmd-menu .rmd-list {
    font-weight: 500;
  }
}
