@import '../../scss/imports';

.node {
  &.searchIncluded h4 {
    color: #ff5722;
    font-size: .875rem;
  }

  &.searchExcluded h4 {
    opacity: .6;
  }
}

.back-button {
  display: flex;
  justify-content: left;
}

.tree-controls-container {
  display: flex;
  justify-content: center;
}
