// Material Icons

.material-icons {
  &.md-18 {
    font-size: 1.125rem;
  }

  &.md-24 {
    font-size: 1.5rem;
  }

  &.md-36 {
    font-size: 2.25rem;
  }

  &.md-48 {
    font-size: 3rem;
  }

  &.md-light {
    color: rgba($blue, 1);
  }

  &.md-light.md-inactive {
    color: rgba($blue, .3);
  }

  &.md-dark {
    color: $text-color;
  }

  &.md-dark.md-inactive {
    color: rgba($white, .3);
  }

  &.md-icon {
    font-size: 1.5rem;
  }
}
