@import '../../scss/imports';

body {
  overscroll-behavior-y: none;
}

#snackbar {
  background-color: #323232;
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .2);
  margin-bottom: 1.5rem;
  min-height: 3.5rem;
  width: 49.125rem;

  p {
    margin: auto .625rem;
  }

  button {
    color: #7c4dff;
    font-weight: bold;
  }
}
