@import '../../scss/imports';

.full-container {
  align-items: center;
  background: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: .25rem;
  color: hsl(0, 0%, 50%);
  display: flex;
  font-family: $font-light;
  justify-content: space-between;
  margin: 0 0 1.5rem;
  min-height: 3.5rem;

  &:hover * {
    cursor: default;
  }

  .tag-container {
    padding: 0 0 0 .25rem;
    width: 100%;

    .label-container {
      border-radius: .125rem;
      color: #333;
      display: flex;
      font-size: 1rem;
      height: 1.5rem;
      justify-content: space-between;
      margin: .5rem .125rem;
      padding-right: .5rem;
      text-align: left;

      .flag {
        border-radius: .1875rem;
        font-size: .75rem;
        padding: .125rem .3125rem;
        text-align: center;
        text-transform: uppercase;
      }

      .remove-label {
        color: rgba(18, 18, 18, .2);
        text-decoration: line-through;
      }

      .remove {
        background: rgba(255, 11, 11, .2);
      }

      .add {
        background: rgba(0, 227, 64, .2);
      }
    }

    .label-display-name {
      align-items: center;
      display: flex;
    }

    .label-tag {
      margin-right: .625rem;
      padding: .1875rem .1875rem .1875rem .375rem;
    }

    .label-button {
      i {
        color: #a8a8a8;
        transform: scale(.8, .8);

        &:hover {
          color: #777;
        }
      }
    }
  }
}
