@import '../../scss/imports';

.no-data-container {
  color: #bbb;
  text-align: center;

  .big-question {
    font-size: 6.875rem;
    margin-bottom: 0;
  }

  .no-data-message {
    font-size: 1.875rem;
  }
}
