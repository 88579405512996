@import 'variables';
@import '~@react-md/theme/dist/mixins';

.tertiary-button {
  @include rmd-theme-update-var(primary, $tertiary-color);
  @include rmd-theme-update-var(on-primary, $tertiary-text-color);
}

.warning-button {
  @include rmd-theme-update-var(primary, $warning-color);
  @include rmd-theme-update-var(on-primary, $warning-text-color);
}

.no-elevate {
  box-shadow: none;
}
