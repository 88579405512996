.activation-dialog {
  max-width: 30rem;
  min-height: 10.875rem;
  min-width: 21.125rem;

  h2 {
    font-size: 1.375rem;
  }

  .activation-button-container {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    width: 100%;

    button {
      width: 8.3125rem;
    }
  }
}

.focus-dialog {
  .md-title--toolbar {
    margin-top: 0;
  }

  .md-btn--toolbar {
    margin-bottom: .875rem;
    margin-top: .875rem;

    i {
      vertical-align: middle;
    }
  }

  .wrapper {
    padding-top: 6.25rem;

    @include min-screen($landscape) {
      padding-top: 10rem;
    }
  }
}
